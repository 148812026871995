<template>
  <v-card id="control-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon color="warning">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <v-tab-item>
        <control-settings-general
          :control-data="controlData"
          :control-data-original="controlDataOriginal"
          :mode="mode"
          :controls="controls"
        ></control-settings-general>
      </v-tab-item>
    </v-tabs-items>

    <v-card
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid && controlData.status !== 'LOCKED'"
                @click.prevent="saveControl"
              >
                Save & Exit
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click.prevent="cancel"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              v-if="mode === 'update' && !isReadOnly()"
              v-show="!isLoading"
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                :disabled="isReadOnly()"
                @click="deleteItem"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiLockOutline, mdiKeyChain, mdiFormatListChecks,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import {
  getControl,
  listControls,
} from '../../graphql/queries'
import {
  createControl,
  updateControl,
  deleteControl,
} from '../../graphql/mutations'
import ControlSettingsGeneral from './ControlSettingsGeneral.vue'
import store from '../../store'

export default {
  /**
   * components
   */
  components: {
    ControlSettingsGeneral,
  },

  /**
   * data
   */
  data() {
    return {
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      controlData: { config: { consent_required: true } },
      controlDataOriginal: { config: { consent_required: true } },
      controls: [],
    }
  },

  /**
   * computed
   */
  computed: {
    /**
     * isFormValid
     */
    isFormValid() {
      const missingFields = []
      if (this.mode === 'update' && !this.controlData.id) {
        missingFields.push('ID')
      }
      if (this.controlData.score === undefined || this.controlData.score === null || this.controlData.score < 0) {
        missingFields.push('Score')
      }
      if (!this.controlData.value) {
        missingFields.push('Value')
      }
      if (this.controlData.value && ((this.mode === 'create' && this.controlData.value.toLowerCase().includes('quasr.io')) || this.controls.find(c => c.value === this.controlData.value && c.id !== this.controlData.id))) {
        missingFields.push('Value')
      }
      if (!this.controlData.status) {
        missingFields.push('Status')
      }
      if (!this.controlData.subtype) {
        missingFields.push('Subtype')
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      return missingFields.length === 0
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    this.showAlert('Control requiredness is now configured through client rules.', 'OK', 'info')
    await this.getControl()
    this.getControls()
    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {
    /**
     * getControl
     */
    async getControl() {
      this.isLoading = true
      if (this.mode === 'create') {
        this.controlData = {
          type: 'control',
          status: 'ENABLED',
          config: { consent_required: true },
        }
      } else {
        try {
          const control = await store.getAppSyncClient().query({ query: gql(getControl), variables: { id: this.$route.params.id } })
          if (!control.data.getControl.config) control.data.getControl.config = {}
          this.controlData = control.data.getControl
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.cancel()
          }
        }
      }
      this.controlDataOriginal = JSON.parse(JSON.stringify(this.controlData))
      this.isLoading = false
    },

    /**
     * getControls
     */
    async getControls() {
      const controls = await store.getAppSyncClient().query({ query: gql(listControls), variables: { limit: 1000 } })
      this.controls = controls.data.listControls.items
    },

    /**
     * isReadOnly
     */
    isReadOnly() {
      const read_only = this.controlData?.value && this.controlData?.value !== '' && (this.controlData.value.includes('quasr.io') || this.controlData.value === 'openid')

      return read_only || false
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteControl(this.controlData.id)
      store.message = { text: 'Control deleted successfully.', action: 'OK', color: 'success' }
      this.closeDelete()
    },

    /**
     * deleteControl
     */
    async deleteControl(id) {
      this.isLoading = true
      await store.getAppSyncClient().mutate({ mutation: gql(deleteControl), variables: { input: { id } } })
      this.showAlert('Control successfully deleted.', 'OK')
      this.cancel()
    },

    /**
     * saveControl
     */
    async saveControl() {
      this.isLoading = true

      // save control
      const obj = {
        label: this.controlData.label,
        status: this.controlData.status,
        score: parseInt(this.controlData.score, 10),
        config: {
          consent_required: this.controlData.config.consent_required || false,
          permission_required: this.controlData.config.permission_required || false,
        },
      }

      if (this.mode === 'create') {
        obj.value = this.controlData.value
        obj.subtype = this.controlData.subtype
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(createControl), variables: { input: obj } })
        if (cmd.data.createControl) {
          this.controlData = cmd.data.createControl
          this.mode = 'update'

          // await this.updateConsentsAndPermissions()
          // this.showAlert('Control saved successfully.', 'OK', 'success')
          store.message = { text: 'Control saved successfully.', action: 'OK', color: 'success' }
          this.cancel()
        } else {
          this.showAlert('Saving control failed.', 'OK', 'error')
          this.isLoading = false
        }
      } else if (this.mode === 'update') {
        obj.id = this.controlData.id
        obj.subtype = this.controlData.subtype
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateControl), variables: { input: obj } })
        if (cmd.data.updateControl) {
          // await this.updateConsentsAndPermissions()
          // this.showAlert('Control saved successfully.', 'OK', 'success')
          store.message = { text: 'Control saved successfully.', action: 'OK', color: 'success' }
          this.cancel()
        } else {
          this.showAlert('Saving control failed.', 'OK', 'error')
          this.isLoading = false
        }
      }
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const tab = ref('')
    const isLoading = true

    // tabs
    const tabs = [
      { title: 'Control Settings', icon: mdiFormatListChecks },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      isLoading,
      loginStatus,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiLockOutline,
        mdiKeyChain,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
