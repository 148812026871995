<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiFormatListChecks }}
      </v-icon>
      <span class="text-break">Control Settings</span>
    </v-card-title>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="controlData.id"
              label="Control ID"
              dense
              outlined
              :disabled="true"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="controlData.label"
              label="Label"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="controlData.value"
              label="Value *"
              :rules="uniqueValueRule"
              :disabled="mode === 'update'"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="controlData.subtype"
              :rules="requiredRule"
              :disabled="mode === 'update'"
              dense
              outlined
              label="Subtype *"
              :items="subtypes"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div v-if="controlData.status === 'LOCKED'">
              Status: <strong>{{ controlData.status }}</strong>
            </div>
            <v-select
              v-if="controlData.status !== 'LOCKED'"
              v-model="controlData.status"
              :rules="requiredRule"
              dense
              outlined
              label="Status *"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="controlData.score"
              label="Score *"
              :rules="positiveNumberRule"
              min="0"
              dense
              type="number"
              clearable
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="controlData.subtype === 'scope'">
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="controlData.config.consent_required"
              dense
              outlined
              label="Consent Required"
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="controlData.config.permission_required"
              dense
              outlined
              label="Permission Required"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiFormatListChecks,
} from '@mdi/js'

export default {
  /**
   * props
   */
  props: {
    controlData: {
      type: Object,
      default: () => {},
    },
    controlDataOriginal: {
      type: Object,
      default: () => {},
    },
    controls: {
      type: Array,
      default: () => [],
    },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      positiveNumberRule: [
        value => (value !== undefined && value !== null && parseInt(value, 10) >= 0) || 'Value must be >= 0',
      ],
      requiredRule: [
        value => !!value || 'Required',
      ],
      uniqueValueRule: [
        value => (!!value && !value.includes('quasr.io') && !this.controls.find(c => c.value === value && c.id !== this.controlData.id)) || 'A unique value is required. Note that the Quasr URLs as prefix are reserved.',
      ],
    }
  },

  /**
   * methods
   */
  methods: {
    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * isReadOnly
     */
    isReadOnly() {
      const read_only = this.mode === 'update'
        && this.controlDataOriginal?.value
        && this.controlDataOriginal?.value !== ''
        && (this.controlDataOriginal.value.includes('quasr.io') || this.controlDataOriginal.value === 'openid')

      return read_only || false
    },
  },

  /**
   * setup
   */
  setup() {
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]
    const subtypes = [
      { text: 'Scope', value: 'scope' },
      { text: 'Legal', value: 'legal' },
    ]

    return {
      status,
      subtypes,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiFormatListChecks,
      },
    }
  },
}
</script>
